<script>
export default {
  metaInfo: {
    title: "Explorer History",
  }
};
</script>

<script setup>
import { defineProps, onBeforeMount, inject } from "vue";
import { useRouter } from "vue-router/composables";
import { useExplorerHistory, BASIC_INFO_COLUMNS, HISTORY_TABLE_COLUMNS } from "@/composition/explorers/use-explorer-history";
import { JOURNAL_INFO_COLS_FOR_TABLE_VIEW } from "@/composition/tables/use-table-history";
import UiHistoryView from "@/components/basic/views/UiHistoryView.vue";
import XSelect from "@/components/basic/XSelect.vue";
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import ExplorerHwBrand from "@/components/specific/Explorer/ExplorerHwBrand.vue";
import ExplorerStatus from "@/components/specific/Explorer/ExplorerStatus.vue";
import ClickableText from "@/components/basic/ClickableText.vue";
import { ITEMS_PER_PAGE_OPTIONS_DEFAULT } from "@/composition/tables/use-table-settings";
import { useExplorers } from "@/composition/explorers/use-explorers";

const props = defineProps({
  id: {
    type: [Number, String],
    required: true
  }
});

const router = useRouter();

const {
  meta,
  init,
  history,
  lastExplorerState,
  updateParams,
  params,
  isLoading,
  explorerName,
} = useExplorerHistory({ id: props.id });

const {
  useExplorerAlias,
  formatExplorerNameWithAlias,
} = useExplorers();

const {
  openDialog
} = inject("DialogsRoot");

const openExplorerDialog = async ({ id }) => {
  await openDialog("ExplorerDialog", { explorerId: id, value: true })
};

const goBack = () => {
  if (window.history.length > 2) {
    router.back();
  } else {
    router.push({ name: "sims" });
  }
};

onBeforeMount(async () => {
  await init();
});
</script>

<template>
  <UiHistoryView
    class="explorer-history-view"
    :is-loading="isLoading"
    :search-text="params.search"
    :history-item-name="explorerName"
    :basic-info-cols="BASIC_INFO_COLUMNS"
    :basic-info="lastExplorerState"
    :journal-info-cols="JOURNAL_INFO_COLS_FOR_TABLE_VIEW"
    :object-info-cols="HISTORY_TABLE_COLUMNS"
    :items="history"
    :sort-by="params.sortBy"
    :sort-desc="params.descending"
    @go-back="() => goBack()"
    @update:search-text="(txt) => updateParams({ ...params, search: txt })"
    @update:sorting="({ sortBy, sortDesc }) => updateParams({ ...params, sortBy, descending: sortDesc })"
  >
    <template #basic-table-cell(history-createDate)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(history-action)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(history-createDate)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(history-modifyDate)="{ cellValue, col }">
      {{ col.formatter(cellValue) }}
    </template>

    <template #cell(name)="{ item: expl }">
      <div class="explorers-view__explorer-name">
        <ExplorerHwBrand
          :degust-image-version="expl.systemInfo.systemType.degustImageVersion"
          size="24"
        />

        <template v-if="!useExplorerAlias || !expl['alias']">
          <ClickableText
            :text="formatExplorerNameWithAlias(expl)"
            @click="() => openExplorerDialog({ id: expl.id })"
          />

          <div v-if="expl['alias']">
            ({{ expl["alias"] }})
          </div>
        </template>

        <template v-else>
          <ClickableText
            :text="expl['alias']"
            @click="() => openExplorerDialog({ id: expl.id })"
          />

          <div>
            ({{ formatExplorerNameWithAlias(expl) }})
          </div>
        </template>
      </div>
    </template>

    <template #cell(systemInfo-systemType-networkInterfaces-eth0)="{ cellValue }">
      {{ cellValue || "" }}
    </template>

    <template #cell(status)="{ cellValue }">
      <ExplorerStatus :value="cellValue"/>
    </template>

    <template #cell(description)="{ cellValue }">
      <p class="explorers-view__description">
        {{ cellValue }}
      </p>
    </template>

    <template #cell(tags)="{ cellValue }">
      <div class="explorers-view__tags">
        <ClickableText 
          v-for="(tag, i) of cellValue"
          :key="i"
          :text="tag"
          @click="() => updateParams({ ...params, tags: tag })"
        />
      </div>
    </template>

    <template #pagination>
      <div
        v-if="meta.page"
        class="explorer-history-view__footer"
      >
        <PaginationBlock
          :total-count="meta.totalCount"
          :current-page="meta.page"
          :items-per-page="meta.itemsPerPage"
          @update:current-page="(pageN) => updateParams({ ...params, page: pageN })"
        />

        <XSelect
          class="explorer-history-view__items-per-page-select"
          :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
          :value="meta.itemsPerPage"
          @input="(_iPerPage) => updateParams({ ...params, itemsPerPage: _iPerPage})"
          label="Items per page"
          :autocomplete="false"
        />
      </div>
    </template>
  </UiHistoryView>
</template>

<style lang="scss">
:root {
  --v-application-wrap-display: grid;
  --v-application-wrap-overflow: hidden;
  --main-v3-max-height: initial;
}

.explorer-history-view {
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }

  &__tags-cell {
    max-width: 100px;
    min-width: 150px;
  }
}
</style>
