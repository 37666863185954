import { ref, computed, onScopeDispose } from "vue";
import { unixToDateTimeString } from "@/js/general"
import { cockpitExplorerService } from "@/api";

export const BASIC_INFO_COLUMNS = [
  { name: "Last name", key: "name", sortable: false, formatter: (v) => v },
  {
    name: "Created At",
    key: "history.createDate",
    sortable: false,
    formatter: unixToDateTimeString,
  },
];

export const HISTORY_TABLE_COLUMNS = [
  { name: "Name", key: "name", sortable: true, },
  { name: "Service Code", key: "serviceCode", sortable: true, },
  { name: "MAC address", key: "systemInfo.systemType.networkInterfaces.eth0", sortable: false, },
  { name: "Status", key: "status", sortable: true, },
  { name: "Description", key: "description", sortable: true, },
  { name: "Tags", key: "tags", sortable: false, },
  { name: "Device Pool", key: "devicePool.name", sortable: false },
];

export const useExplorerHistory = ({ id, refreshMs = 30000 }) => {
  const explorerHistory = ref([]);
  const lastExplorerState = ref(null);
  const isLoading = ref(false);
  const meta = ref({});
  const params = ref({
    page: 1,
    itemsPerPage: 25,
    search: "",
    sortBy: "",
    descending: false,
    from: Math.trunc(new Date(0).getTime() / 1000),
    to: Math.trunc(new Date(new Date().setHours(24, 0, 0, 0)).getTime() / 1000),
    devicePool: 0,
  });

  const updateMeta = (newMeta) => {
    meta.value = newMeta
  };

  const retrieveMeta = (resp) => {
    const { page, pageCount, perPage: itemsPerPage, totalCount, columns } = resp._metadata

    return {
      page,
      pageCount,
      itemsPerPage,
      totalCount,
      columns,
    }
  };

  const _prepareParams = (_params) => {
    // eslint-disable-next-line no-undef
    const _p = structuredClone(_params);
    if (_p.sortBy) {
      _p.sortBy = _p.sortBy.split(".").pop();
    }
    return _p;
  };

  const updateHistoryData = async (_params, { isSilent = false } = {}) => {
    if (!isSilent) {
      isLoading.value = true
    }

    try {
      const promises = [cockpitExplorerService.v1.getExplorerHistoryTable(id, _params)];

      if (_params.page === 1) {
        promises.push(cockpitExplorerService.v1.getExplorer(id, { version: 0 }));
      }

      const [historyResponse, explorer] = await Promise.all(promises);

      if (explorer) {
        lastExplorerState.value = explorer;
      }

      if (historyResponse.records && historyResponse.records.length > 0) {
        explorerHistory.value = historyResponse.records;
        updateMeta(retrieveMeta(historyResponse));
      } else {
        explorerHistory.value = [];
      }
    } catch (err) {
      lastExplorerState.value = null
      explorerHistory.value = [];
      throw err;
    } finally {
      isLoading.value = false;
    }
  };

  const updateParams = (newParams) => {
    params.value = newParams
    updateHistoryData(_prepareParams(params.value))
  };

  let intervalId = null;
  onScopeDispose(() => {
    clearInterval(intervalId);
  });
  const init = async () => {
    await updateHistoryData(_prepareParams(params.value));

    intervalId = setInterval(() => {
      updateHistoryData(_prepareParams(params.value), { isSilent: true });
    }, refreshMs);
  };

  const explorerName = computed(() => lastExplorerState.value?.name || "Not found");

  const history = computed(() => {
    if (params.value.page === 1) {
      const item = lastExplorerState.value ? [lastExplorerState.value] : [];
      return [].concat(item, explorerHistory.value);
    }
    return explorerHistory.value;
  });

  return {
    lastExplorerState,
    history,
    meta,
    init,
    params,
    explorerName,
    isLoading,
    updateParams,
  };
};
